/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html {
  height: 100%;
  background-color: #23252c;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
  height: 100%;
}
/*
CSS RESET ENDS
*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Inter", sans-serif;
}
.container {
  max-width: 317px;
  margin: 0 auto;
  background-color: #1a1b21;
}

.info {
  text-align: center;
}

.info > h1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  margin-top: 21px;
}

.info > h3 {
  font-size: 1.25rem;
  font-weight: 400;
  color: #fff;
  color: #f3bf99;
  margin-top: 6px;
  margin-bottom: 9px;
}

.info > a {
  text-decoration: none;
  color: #fff;
}
.info-button-container {
  margin: 0 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button {
  cursor: pointer;
  margin-top: 15px;
  padding: 9px 13px 9px 11px;
  width: 115px;
  border-radius: 8px;
}

.button__email {
  color: #1e1f26;
}
.button__linkedin {
  background-color: #5093e2;
  color: #fff;
  border: 1px solid #5093e2;
}

.info-button-container > button > i {
  margin-right: 9.6px;
}
.info-button-container > button > a {
  text-decoration: none;
  color: inherit;
}
.about-container {
  padding: 2rem 2.25rem 1rem 2.25rem;
}

.about-header {
  font-size: 1rem;
  font-weight: 700;
}
.about-text {
  font-size: 0.75rem;
}

.interests-container {
  padding: 0rem 2.25rem 1.5rem 2.25rem;
}

.interests-header {
  font-size: 1rem;
  font-weight: 700;
}
.interests-text {
  font-size: 0.75rem;
}

.footer-container {
  background-color: #161619;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 4rem;
  color: #918e9b;
}
.footer-container > a {
  color: inherit;
}
